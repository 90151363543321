export const ProjectData2 = [
    {
        no: 4,
        title: `Rishwith Karthi's`,
        // path: '/projects/venkatramana',
        path: '/projects',
        img: ['./../images/P4/1.jpg', './../images/P4/2.jpg', './../images/P4/3.jpg', './../images/P4/4.jpg', './../images/P4/5.jpg', './../images/P4/6.jpg', './../images/P4/7.jpg', './../images/P4/8.jpg', './../images/P4/9.jpg', './../images/P4/10.jpg', './../images/P4/11.jpg', './../images/P4/12.jpg', './../images/P4/13.jpg', './../images/P4/14.jpg', './../images/P4/15.jpg', './../images/P4/16.jpg'],
        owner: 'Rishwith Karthi',
        sdate: '444',
        cdate: 'June, 2022',
        location: 'Risinia Trendilla, Bachupally, Hyderabad, Telangana',
        area: '1300 sqft.',
        category: 'Residential Interiors',
        head: 'Scandinavian',
        desc1: 'Design & Execution',
        desc2: 'Interiors in white and grey are crisp, clean, and beautiful. ',
        desc3: `The color scheme is extremely versatile; it can be all of the above with just a hint of black, and it works well with almost any colour.`,
        desc4: `White is frequently used to create a luxurious feeling within an interior space , so  Stay ahead of this trend by emphasising its benefits on shelves, tables, flooring, and walls.`
    },
    {
        no: 5,
        title: `Sridhar's`,
        // path: '/projects/sridhar',
        path: '/projects',
        img: ['./../images/P5/1.jpg', './../images/P5/2.jpg', './../images/P5/3.jpg', './../images/P5/4.jpg', './../images/P5/5.jpg', './../images/P5/6.jpg', './../images/P5/7.jpg', './../images/P5/8.jpg', './../images/P5/9.jpg', './../images/P5/10.jpg', './../images/P5/11.jpg', './../images/P5/12.jpg', './../images/P5/13.jpg', './../images/P5/14.jpg', './../images/P5/15.jpg', './../images/P5/16.jpg'],
        owner: 'Sridhar',
        sdate: '555',
        cdate: 'Aug, 2022',
        location: 'Risinia Trendilla, Bachupally, Hyderabad, Telangana',
        area: '1800 sqft.',
        category: 'Residential Interiors',
        head: 'Scandinavian',
        desc1: 'Design & Execution',
        desc2: `We advised a Scandinavian-themed interior design because the client desired more whites and greys in the interiors. `,
        desc3: `The warm, luxurious feel of Scandinavian interior design is well known. Clean lines and geometric shapes define this design, which is also light and airy.`,
        desc4: `This style's colors are neutral and soft, with whites and grays being especially popular. Scandinavian style is growing in popularity year after year.`
    },
    {
        no: 6,
        title: `Shridhar Malini's`,
        // path: '/projects/sridharmalini',
        path: '/projects',
        img: ['./../images/P6/1.jpg', './../images/P6/2.jpg', './../images/P6/3.jpg', './../images/P6/4.jpg', './../images/P6/5.jpg', './../images/P6/6.jpg', './../images/P6/7.jpg', './../images/P6/8.jpg', './../images/P6/9.jpg', './../images/P6/10.jpg', './../images/P6/11.jpg', './../images/P6/12.jpg', './../images/P6/13.jpg', './../images/P6/14.jpg', './../images/P6/15.jpg', './../images/P6/16.jpg'],
        owner: 'Shridhar Malini',
        sdate: '666',
        cdate: 'Oct, 2022',
        location: 'Secunderabad, Telangana',
        area: 'NA',
        category: 'Elevation Design',
        head: 'Indo Westren',
        desc1: 'Design',
        desc2: `This contemporary elevation design has a wood and concrete exterior that distinguishes it from other homes in the neighborhood.`,
        desc3: `The open concept family room has high ceilings and plenty of natural light. This modern elevation design with a wood and concrete exterior will enhance your living space. `,
        desc4: `The perfect balance of form, function, and beauty will improve the building's appearance and feel.`
    },
];
